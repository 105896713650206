import React, { useState } from "react";
import "./Header.css";
/*import image*/
import logo from "../../assets/logo.png";
import Bars from "../../assets/bars.png";
import { Link } from "react-scroll";

const Header = () => {
  const mobile = window.innerWidth <= 768 ? true : false;
  const [menuOpened, setMenuOpenned] = useState(false);
  return (
    <div className="header">
      <img src={logo} alt="Logo" className="logo" />
      {menuOpened === false && mobile === true ? (
        <div
          style={{
            backgroundColor: "var(--appColor)",
            padding: "0.5rem",
            borderRadius: "5px",
          }}
          onClick={() => setMenuOpenned(true)}
        >
          <img
            src={Bars}
            alt=""
            style={{ width: "1.5rem", height: "1.5rem" }}
          />
        </div>
      ) : (
        <ul className="header-menu">
          <li>
            <Link
              onClick={() => setMenuOpenned(false)}
              to="home"
              spy={
                true
              } /* this is to make sure that the page scrolls to the testimonials section */
              smooth={true}
            >
              Home
            </Link>
          </li>
          <li onClick={() => setMenuOpenned(false)}>
            <Link
              onClick={() => setMenuOpenned(false)}
              to="programs"
              spy={true}
              smooth={true}
            >
              Programs
            </Link>
          </li>
          <li onClick={() => setMenuOpenned(false)}>
            <Link
              onClick={() => setMenuOpenned(false)}
              to="reasons"
              spy={true}
              smooth={true}
            >
              Why us
            </Link>
          </li>
          <li onClick={() => setMenuOpenned(false)}>
          <Link
              onClick={() => setMenuOpenned(false)}
              to="plans"
              spy={true}
              smooth={true}
            >
              Plans
            </Link>
          </li>
          <li>
            <Link
              onClick={() => setMenuOpenned(false)}
              to="testimonials"
              spy={true}
              smooth={true}
            >
              Testimonials
            </Link>
          </li>
        </ul>
      )}
    </div>
  );
};

export default Header;
